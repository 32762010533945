<template>
  <div>
    <b-card>
      <!-- 검색영역 -->
      <b-input-group class="mb-2">
        <!-- 검색버튼 -->
        <b-input-group-prepend>
          <b-button variant="primary" @click="getList" :disabled="isLoading.list">
            <i class="fa fa-search"/> 검색
            <b-spinner class="mr-1" small v-if="isLoading.list"/>
          </b-button>
        </b-input-group-prepend>
        <!-- 검색어 -->
        <b-form-input type="text" placeholder="검색어를 입력해주세요."
                      v-model="searchParams.keyword"
                      @keydown.enter.prevent.stop="getList"
                      v-focus/>
      </b-input-group>
      <b-row class="mb-2">
        <!-- 카테고리 검색 -->
        <b-col md="12">
          <div class="pt-2">
            <v-select
              multiple
              autocomplete
              clearable
              :closeOnSelect="false"
              v-model="searchParams.categoryList"
              :options="searchCategoryList"
              label="text"
              value="value"
              :placeholder="searchCategoryList && searchCategoryList.length ? `카테고리를 선택해주세요.` : '로딩중...'"/>
          </div>
        </b-col>
      </b-row>
      <!-- 하단 버튼영역 -->
      <div class="">
        <b-button class="mr-1" variant="primary" @click="getList" :disabled="isLoading.list">
          검색
          <b-spinner class="ml-1" small v-if="isLoading.list"/>
        </b-button>
        <b-button
            v-if="isAuth.add"
            variant="success"
            @click="doViewOpen('SAVE')"
        >
          추가
        </b-button>
      </div>
    </b-card>

    <b-alert show variant="danger">※ 카테고리 대표 규격은 카테고리 그룹 단위로만 설정할 수 있습니다. 대표 규격 설정이 필요한 카테고리는 [상세] 버튼을 클릭하여 기존 그룹에 추가 하거나, [추가] 버튼을 클릭하여 신규 등록할 수 있습니다.</b-alert>

    <div class="btn-ext-area">
      <b-button class="btn-not-group mb-3" variant="primary" @click="doNotGroupMdlOpen">※ 그룹핑 되지 않은 카테고리 리스트가 {{ this.notSettingCateMdl.dataList.length || '0' }} 개 있습니다.</b-button>
      <b-button class="btn-excel mb-3" variant="success" @click="doExcelDown">XLSX</b-button>
    </div>
    <!-- 검색 리스트 -->
    <b-card>
      <slot name="header"></slot>
      <b-row class="form-group">
        <b-col cols="10" class="col-form-label">
          <div class="d-inline" v-html="data.length + ' 개'"></div>
        </b-col>
        <b-col cols="2" class="clearfix">
          <b-form inline class="pull-right">
            <b-form-select :options="pagination.perPageList" v-model="pagination.perPage"></b-form-select>
          </b-form>
        </b-col>
      </b-row>

      <b-table
          responsive
          show-empty
          :items="data"
          :fields="headers"
          :per-page="pagination.perPage"
          :current-page="pagination.currentPage"
          :sticky-header="false"
          :busy="isLoading.list">
        <!-- 전체선택 Header -->
        <template v-slot:head(selected)>
          <b-form-checkbox v-model="allSelected" @input="doItemAllCheck"></b-form-checkbox>
        </template>
        <!-- 전체선택 Row -->
        <template v-slot:cell(selected)="data">
          <b-form-checkbox :id="tblId + '_checkbox'+data.index" v-model="data.item.selected" @input="doItemCheck"></b-form-checkbox>
        </template>
        <!-- 카테고리 Row -->
        <template v-slot:cell(category_code_path_list)="data">
          <div v-html="data.item.category_code_path_list.join(`<br>`)"/>
        </template>
        <!-- 카테고리코드 Row -->
        <template v-slot:cell(category_code_list)="data">
          <div v-html="data.item.category_code_list.join(`<br>`)"/>
        </template>
        <!-- 관리 Row -->
        <template v-slot:cell(setting)="data">
          <div class="setting-area">
            <b-button size="sm" @click="doViewOpen('VIEW', data.item.id)">상세</b-button>
            <template v-if="isAuth.add">
              <b-button size="sm" variant="danger" @click="doDelete(data.item.id)">삭제</b-button>
            </template>
          </div>
        </template>
        <!-- 리스트 로딩바 -->
        <template v-slot:table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
      <!-- 테이블 하단 영역 -->
      <b-row>
        <b-col lg="10">
          <div class="clearfix">
            <nav class="pull-left">
              <b-pagination :total-rows="data.length" :per-page="pagination.perPage" :limit="11" v-model="pagination.currentPage" prev-text="Prev" next-text="Next"/>
            </nav>
          </div>
        </b-col>
        <b-col class="d-none d-lg-block clearfix" lg="2">
          <b-form inline class="pull-right">
            <b-form-select :options="pagination.perPageList" v-model="pagination.perPage"></b-form-select>
          </b-form>
        </b-col>
      </b-row>
      <slot name="footer"></slot>
    </b-card>

    <!-- 카테고리 대표 규격 미설정 리스트 모달 -->
    <b-modal
        title="카테고리 대표 규격 미설정 리스트"
        size="lg"
        modal-class="modal-fullscreen"
        hide-footer
        v-model="notSettingCateMdl.isOpen"
    >
      <div class="mdl-use-body">
        <!-- 로딩바 -->
        <template v-if="notSettingCateMdl.isLoading">
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template v-else>
          <!-- 데이터가 존재하지 않을 경우 -->
          <template v-if="notSettingCateMdl.dataList.length === 0 && notSettingCateMdl.dataList.length === 0">
            <div class="no-data">검색 데이터가 존재하지 않습니다.</div>
          </template>
          <!-- 카테고리대표규격관리 데이터가 있을 경우 -->
          <template v-if="notSettingCateMdl.dataList.length > 0">
            <b-table
                responsive
                outlined
                sticky-header="550px"
                :items="notSettingCateMdl.dataList"
                :fields="notSettingCateMdl.table.headers"/>
          </template>
        </template>
      </div>
    </b-modal>

    <!-- 카테고리대표규격관리 등록/수정 모달 -->
    <option-category-view ref="optionCategoryView" @save="doSaveFinished"/>
    <!-- 엑셀 다운로드 -->
    <iframe ref="ifr" name="xlsx_frame" style="width:1px;height:1px;visibility:hidden"></iframe>
    <form :action="$api.getHost() + '/meta/optionCategory/down'" ref="xlsx_form" method="POST" target="xlsx_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="ids" />
    </form>
  </div>
</template>

<script>
import * as utils from '@/shared/utils';
import optionCategoryView from '@/views/modal/meta/OptionCategoryView.vue';
export default {
  name: "MetaOptionCategory",
  components: {optionCategoryView},
  data() {
    // 테이블 정보 설정
    const tblInfo = {
      tdAttr: {style: 'vertical-align:top;'}
    };

    return {
      tblId: null, // 테이블 ID
      searchParams: {
        keyword: '', // 검색어
        categoryList: [] // 사용여부
      },
      searchCategoryList: [], // 카테고리 리스트 Select
      // 로딩바
      isLoading: {
        list: false
      },
      // 권한
      isAuth: {
        add: false // 추가 권한
      },
      headers: [
        {key: 'selected', class: 'w-65px', tdAttr: tblInfo.tdAttr},
        {key: 'name', label: '그룹명', class: 'text-center', sortable: false, thStyle: 'min-width:120px;', tdAttr: tblInfo.tdAttr},
        {key: 'category_code_path_list', label: '카테고리', class: '', sortable: false, thStyle: 'min-width:400px;', tdAttr: tblInfo.tdAttr},
        {key: 'category_code_list', label: '카테고리 코드', class: '', sortable: false, thStyle: 'min-width:200px;', tdAttr: tblInfo.tdAttr},
        {key: 'option_public_unit_name', label: '표준 옵션 단위', class: 'text-center', sortable: false, thStyle: 'min-width:110px;', tdAttr: tblInfo.tdAttr},
        {key: 'option_sizes', label: '표준 옵션 상세 정보', class: '', sortable: false, thStyle: 'min-width:160px;text-align:center',
          tdAttr: {
            style: 'vertical-align:middle;max-width:500px;'
          }
        },
        {key: 'created_at', label: '최초 생성 일시', class: 'text-center w-250px', sortable: false, thStyle: 'min-width:170px;', tdAttr: tblInfo.tdAttr},
        {key: 'updated_at', label: '최근 수정 일시', class: 'text-center w-250px', sortable: false, thStyle: 'min-width:170px;', tdAttr: tblInfo.tdAttr,
          formatter: function (value) {
            return value || '-';
          }
        },
        {key: 'setting', label: '관리', class: 'text-center', sortable: false, thStyle: 'min-width:80px;', tdAttr: tblInfo.tdAttr},
      ],
      data: [], // 리스트 데이터
      // 페이징 관련
      pagination: {
        perPage: 20, // Page 수
        perPageList: [10,20,30,40,50,100,200,500,1000], // Page 수 리스트
        currentPage: 1, // 현재 페이지
      },
      allSelected: false, // 전체선택여부
      // 카테고리 대표 규격 미설정 리스트
      notSettingCateMdl: {
        isOpen: false, // 모달 Open 여부
        isLoading: false, // 로딩여부
        dataList: [],
        // 카테고리대표규격 미설정 리스트 컬럼정보
        table: {
          headers: [
            {key: 'index', label: '', class: 'text-center', thStyle: 'min-width:130px;', tdAttr: tblInfo.tdAttr},
            {key: 'category_code', label: '카테고리 코드', class: '', thStyle: 'width: 200px;min-width:190px;', tdAttr: tblInfo.tdAttr},
            {key: 'category_path', label: '카테고리 경로', class: '', thStyle: 'min-width:350px;', tdAttr: tblInfo.tdAttr}
          ]
        }
      }
    };
  },
  async created() {
    this.tblId = this.tblId || 'tbl_' + (Math.random() + '').slice(2, 10); // 테이블 아이디
    // 쓰기 권한이 있을 경우
    if (this.$R('META_W')) {
      this.isAuth.add = true; // 표준옵션추가 권한
    }
    await this.getList(); // 리스트 조회
    await this.getSearchCategoryList(); // 카테고리검색 리스트 조회
    await this.getNotGroupList(); // 카테고리대표규격 미설정
  },
  methods: {
    /**
     * 카테고리대표규격관리 리스트 조회 Process
     */
    async getList() {
      const params = new URLSearchParams({
        keyword: this.searchParams.keyword,
        searchCategories: this.searchParams.categoryList.map(e => e.value).join(',')
      });

      const res = await this.$api.getJson(`/meta/optionCategory?${params.toString()}`);
      if (res.ok === 1) this.data = res.data;
      else this.data = [];
    },
    /**
     * 카테고리대표규격관리 미설정 리스트 조회 Process
     */
    async getNotGroupList() {
      const res = await this.$api.getJson(`/meta/optionCategory/group/not`);
      // 성공일 경우
      if (res.ok === 1) {
        this.notSettingCateMdl.dataList = res.data;
      }
      else {
        this.notSettingCateMdl.isOpen = false;
        this.notSettingCateMdl.dataList = [];
      }
    },
    async getSearchCategoryList() {
      const res = await this.$api.getJson(`/meta/optionCategory/search/category`);
      // 성공일 경우
      if (res.ok === 1) this.searchCategoryList = res.data;
      else this.searchCategoryList = [];
    },
    /**
     * 전체선택 체크박스 클릭 이벤트
     * @param isSelected 선택여부
     */
    doItemAllCheck(isSelected) {
      if (isSelected || this.data.length === this.data.filter(e => e.selected).length) this.data.forEach(e => e.selected = isSelected);
    },
    /**
     * Row 선택 체크박스 클릭 이벤트
     * @param isSelected 선택여부
     */
    doItemCheck(isSelected) {
      if (!this.data.length || this.allSelected === isSelected || this.allSelected === this.data.every(e => e.selected)) return;
      this.allSelected = this.data.every(e => e.selected);
    },
    /**
     * 사용내역 보기 버튼 클릭
     */
    doNotGroupMdlOpen() {
      this.notSettingCateMdl.isOpen = true;
    },
    /**
     * 카테고리대표규격관리 삭제 Process
     * @param metaOptionCategoryId
     */
    async doDelete(metaOptionCategoryId) {
      const res = await this.$api.postJson(`/meta/optionCategory/delete/${metaOptionCategoryId}`);

      // 성공일 경우
      if (res.ok === 1) {
        await utils.alert(`해당 카테고리 대표 규격 그룹이 삭제되었습니다.`);
        await this.getList();
        await this.getNotGroupList();
      }
    },
    /**
     * 카테고리대표규격관리 등록/수정창 열기
     * @param type
     * @param metaOptionCategoryId
     */
    doViewOpen(type, metaOptionCategoryId) {
      this.$refs.optionCategoryView.init(type, metaOptionCategoryId);
    },
    /**
     * 카테고리대표규격관리 등록 Callback
     */
    async doSaveFinished() {
      await this.getList(); // 리스트 조회
      await this.getNotGroupList(); // 카테고리 미설정 리스트 조회
    },
    /**
     * 엑셀 다운로드 Process
     */
    async doExcelDown() {
      const selectList = [];

      this.data.forEach(item => {
        if (item.selected) selectList.push(item.id);
      });

      if (selectList.length === 0) {
        return utils.alert(`엑셀 다운로드하려는 항목을 선택해 주세요.`);
      }

      this.$refs.json_data.value = selectList.join(',');
      this.$refs.xlsx_form.submit();
    }
  }
}
</script>
<style scoped>
.mdl-use-body {
  min-height: 20px;
  overflow: auto;
}

.no-data {
  text-align: center;
}

.setting-area {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.setting-area > button {
  width: 40px;
  margin: 0 3px;
}

.btn-ext-area {
  display: flex;
  justify-content: space-between;
}
</style>
