<template>
  <div>
    <b-card class="search-area">
      <!-- 검색영역 -->
      <div>
        <b-input-group class="mb-2">
          <!-- 검색버튼 -->
          <b-input-group-prepend>
            <b-button variant="primary" @click="getList" :disabled="isLoading.list">
              <i class="fa fa-search"/> 검색
              <b-spinner class="mr-1" small v-if="isLoading.list"/>
            </b-button>
          </b-input-group-prepend>
          <!-- 검색어 -->
          <b-form-input type="text" placeholder="검색어를 입력해주세요."
                        v-model="searchParams.keyword"
                        @keydown.enter.prevent.stop="getList"
                        v-focus/>
        </b-input-group>
        <b-row class="mb-2">
          <b-col md="12" class="search-item-area">
            <!-- 그룹 검색 -->
            <div class="search-item">
              <v-select
                  multiple
                  autocomplete
                  clearable
                  :closeOnSelect="false"
                  v-model="searchParams.groupList"
                  :options="searchGroupList"
                  label="text"
                  value="value"
                  :placeholder="searchGroupList && searchGroupList.length ? `카테고리 그룹을 선택해주세요.` : '로딩중...'"
              />
            </div>
            <!-- 브랜드 검색 -->
            <div class="search-item">
              <brand-preset v-model="searchParams.brandList" hide-buttons/>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- 하단 버튼영역 -->
      <div class="">
        <b-button class="mr-1" variant="primary" @click="getList" :disabled="isLoading.list">
          검색
          <b-spinner class="ml-1" small v-if="isLoading.list"/>
        </b-button>
        <b-button
            v-if="isAuth.add"
            variant="success"
            @click="doViewOpen('SAVE')"
        >
          추가
        </b-button>
      </div>
    </b-card>
    <div class="btn-ext-area">
      <b-button class="btn-excel mb-3" variant="success" @click="doExcelDown">XLSX</b-button>
    </div>
    <!-- 검색 리스트 -->
    <b-card>
      <slot name="header"></slot>
      <b-row class="form-group">
        <b-col cols="10" class="col-form-label">
          <div class="d-inline" v-html="data.length + ' 개'"></div>
        </b-col>
        <b-col cols="2" class="clearfix">
          <b-form inline class="pull-right">
            <b-form-select :options="pagination.perPageList" v-model="pagination.perPage"></b-form-select>
          </b-form>
        </b-col>
      </b-row>

      <b-table
          responsive
          show-empty
          :items="data"
          :fields="headers"
          :per-page="pagination.perPage"
          :current-page="pagination.currentPage"
          :sticky-header="false"
          :tbody-tr-class="setCommonSheet"
          :busy="isLoading.list">
        <!-- 전체선택 Header -->
        <template v-slot:head(selected)>
          <b-form-checkbox v-model="allSelected" @input="doItemAllCheck"></b-form-checkbox>
        </template>
        <!-- 전체선택 Row -->
        <template v-slot:cell(selected)="data">
          <b-form-checkbox :id="tblId + '_checkbox'+data.index" v-model="data.item.selected" @input="doItemCheck"></b-form-checkbox>
        </template>
        <!-- 카테고리 Row -->
        <template v-slot:cell(category_code_path_list)="data">
          <div v-html="data.item.category_code_path_list.join(`<br>`)"/>
        </template>
        <!-- 카테고리코드 Row -->
        <template v-slot:cell(category_code_list)="data">
          <div v-html="data.item.category_code_list.join(`<br>`)"/>
        </template>
        <!-- 표준옵션상세정보 > 보기 Row -->
        <template v-slot:cell(option_public_unit_names)="data">
          <template v-if="data.item.option_public_unit_names">
            <div>
              <div>{{ data.item.option_public_unit_names }}</div>
              <b-button class="btn-option-view" size="sm" variant="outline-primary"  @click="doOptionDetailShow(data.item.id)">보기</b-button>
            </div>
          </template>
          <template v-else>-</template>
        </template>
        <!-- 관리 Row -->
        <template v-slot:cell(setting)="data">
          <div class="setting-area">
            <b-button size="sm" @click="doViewOpen('VIEW', data.item.id)">상세</b-button>
            <template v-if="isAuth.add">
              <b-button size="sm" variant="danger" @click="doDelete(data.item.id)">해제</b-button>
            </template>
          </div>
        </template>
        <!-- 리스트 로딩바 -->
        <template v-slot:table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
      <!-- 테이블 하단 영역 -->
      <b-row>
        <b-col lg="10">
          <div class="clearfix">
            <nav class="pull-left">
              <b-pagination :total-rows="data.length" :per-page="pagination.perPage" :limit="11" v-model="pagination.currentPage" prev-text="Prev" next-text="Next"/>
            </nav>
          </div>
        </b-col>
        <b-col class="d-none d-lg-block clearfix" lg="2">
          <b-form inline class="pull-right">
            <b-form-select :options="pagination.perPageList" v-model="pagination.perPage"></b-form-select>
          </b-form>
        </b-col>
      </b-row>
      <slot name="footer"></slot>
    </b-card>

    <!-- 표준옵션상세정보 모달 -->
    <b-modal
        title="표준 옵션 상세정보"
        size="lg"
        modal-class="modal-fullscreen"
        hide-footer
        v-model="sheetOptionDetailMdl.isOpen"
    >
      <div class="mdl-use-body">
        <!-- 로딩바 -->
        <template v-if="sheetOptionDetailMdl.isLoading">
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template v-else>
          <!-- 데이터가 존재하지 않을 경우 -->
          <template v-if="sheetOptionDetailMdl.data.list.length === 0">
            <div class="no-data">검색 데이터가 존재하지 않습니다.</div>
          </template>
          <!-- 조견표 데이터가 있을 경우 -->
          <template v-if="sheetOptionDetailMdl.data.list.length > 0">
            <div class="mdl-title">조견표 리스트</div>
            <b-table
                responsive
                outlined
                sticky-header="500px"
                :items="sheetOptionDetailMdl.data.list"
                :fields="sheetOptionDetailMdl.table.headers">
            </b-table>
          </template>
        </template>
      </div>
    </b-modal>

    <!-- 표준옵션관리 등록/수정 모달 -->
    <sheet-view ref="sheetView" @save="doSaveFinished"></sheet-view>
    <!-- 엑셀 다운로드 -->
    <iframe ref="ifr" name="xlsx_frame" style="width:1px;height:1px;visibility:hidden"></iframe>
    <form :action="$api.getHost() + '/meta/sheet/down'" ref="xlsx_form" method="POST" target="xlsx_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="ids" />
    </form>
  </div>
</template>

<script>
import * as utils from '@/shared/utils';
import SheetView from '@/views/modal/meta/SheetView.vue';
export default {
  name: "MetaSizeSheet",
  components: {SheetView},
  data() {
    // 테이블 정보 설정
    const tblInfo = {
      tdAttr: {style: 'vertical-align:middle;'}
    };

    return {
      tblId: null, // 테이블 ID
      searchParams: {
        keyword: '', // 검색어
        brandList: [], // 브랜드
        groupList: [], // 그룹
      },
      searchGroupList: [],
      searchBrandList: [],
      searchCategoryList: [],
      // 로딩바
      isLoading: {
        list: false
      },
      // 권한
      isAuth: {
        add: false // 추가 권한
      },
      headers: [
        {key: 'selected', class: 'w-65px', tdAttr: tblInfo.tdAttr},
        {key: 'brand_id', label: '브랜드 번호', class: 'text-center', sortable: false, thStyle: 'min-width:100px;', tdAttr: tblInfo.tdAttr,
          formatter: function (value) {
            return value || '-';
          }
        },
        {key: 'brandnm', label: '브랜드명(영문)', class: 'text-center', sortable: false, thStyle: 'min-width:150px;', tdAttr: tblInfo.tdAttr,
          formatter: function (value) {
            return value || '-';
          }
        },
        {key: 'brandnm_kr', label: '브랜드명(한글)', class: 'text-center', sortable: false, thStyle: 'min-width:150px;', tdAttr: tblInfo.tdAttr,
          formatter: function (value) {
            return value || '-';
          }
        },
        {key: 'brand_type_name', label: '브랜드 속성', class: 'text-center', sortable: false, thStyle: 'min-width:100px;', tdAttr: tblInfo.tdAttr,
          formatter: function (value) {
            return value || '-';
          }
        },
        {key: 'meta_option_category_name', label: '그룹명', class: 'text-center', sortable: false, thStyle: 'min-width:150px;', tdAttr: tblInfo.tdAttr},
        {key: 'category_code_list', label: '카테고리 코드', class: '', sortable: false, thStyle: 'min-width:200px;', tdAttr: tblInfo.tdAttr},
        {key: 'category_code_path_list', label: '카테고리', class: '', sortable: false, thStyle: 'min-width:400px;', tdAttr: tblInfo.tdAttr},
        {key: 'meta_option_category_option_name', label: '카테고리 대표 옵션 단위', class: 'text-center', sortable: false, thStyle: 'min-width:180px;', tdAttr: tblInfo.tdAttr},
        {key: 'option_public_unit_names', label: '표준 옵션 상세 정보', class: 'text-center', sortable: false, thStyle: 'min-width:180px;',
          tdAttr: {
            style: 'vertical-align:middle;max-width:500px;'
          }
        },
        {key: 'created_at', label: '최초 생성 일시', class: 'text-center w-250px', sortable: false, thStyle: 'min-width:170px;', tdAttr: tblInfo.tdAttr},
        {key: 'updated_at', label: '최근 수정 일시', class: 'text-center w-250px', sortable: false, thStyle: 'min-width:170px;', tdAttr: tblInfo.tdAttr,
          formatter: function (value) {
            return value || '-';
          }
        },
        {key: 'setting', label: '관리', class: 'text-center', sortable: false, thStyle: 'min-width:80px;', tdAttr: tblInfo.tdAttr},
      ],
      data: [], // 리스트 데이터
      // 페이징 관련
      pagination: {
        perPage: 20, // Page 수
        perPageList: [10,20,30,40,50,100,200,500,1000], // Page 수 리스트
        currentPage: 1, // 현재 페이지
      },
      allSelected: false, // 전체선택여부
      // 사용내역 정보
      sheetOptionDetailMdl: {
        isOpen: false, // 모달 Open 여부
        isLoading: false, // 로딩여부
        data: {
          sizeSheetId: null, // 표준옵션관리 고유번호
          list: [],
        },
        // 표준옵션상세정보 리스트 컬럼정보
        table: {
          headers: [
            {key: 'index', label: '#', class: 'text-center', thStyle: 'min-width:130px;', tdAttr: tblInfo.tdAttr},
          ]
        },
      }
    };
  },
  async created() {
    this.tblId = this.tblId || 'tbl_' + (Math.random() + '').slice(2, 10); // 테이블 아이디
    // 쓰기 권한이 있을 경우
    if (this.$R('META_W')) {
      this.isAuth.add = true; // 표준옵션추가 권한
    }
    await this.getList(); // 리스트 조회
    await this.getSearchOptionCategoryList(); // 카테고리대표규격 그룹 리스트 조회
  },
  watch: {
    "sheetOptionDetailMdl.isOpen": function () {
      // 사용내역 Open
      if (this.sheetOptionDetailMdl.isOpen) {
        this.getOptionDetailList(); //
      }
      // 사용내역 Close
      else {
        this.sheetOptionDetailMdl.data.sizeSheetId = null;
        this.sheetOptionDetailMdl.data.list = [];
        this.sheetOptionDetailMdl.table.headers = [
          {key: 'index', label: '#', class: 'text-center', thStyle: 'min-width:50px;', tdAttr: {style: 'vertical-align:middle;'}}
        ];
      }
    }
  },
  methods: {
    /**
     * 사이즈조견표관리 리스트 조회 Process
     */
    async getList() {
      const params = new URLSearchParams({
        keyword: this.searchParams.keyword,
        searchBrands: this.searchParams.brandList.map(e => e.value).join(','),
        searchOptionCategories: this.searchParams.groupList.map(e => e.value).join(',')
      });

      const res = await this.$api.getJson(`/meta/sheet?${params.toString()}`);
      if (res.ok === 1) this.data = res.data;
      else this.data = [];
    },
    /**
     * 표준옵션상세정보 리스트 조회 Process
     */
    async getOptionDetailList() {
      this.sheetOptionDetailMdl.isLoading = true;
      const res = await this.$api.getJson(`/meta/sheet/option/detail/${this.sheetOptionDetailMdl.data.sizeSheetId}`);

      // 성공일 경우
      if (res.ok === 1) {
        this.sheetOptionDetailMdl.data.list = res.data.fields;

        if (res.data.headers) {
          for (const header of res.data.headers) {
            this.sheetOptionDetailMdl.table.headers.push({
              key: header.key,
              label: header.label,
              class: 'text-center',
              thStyle: 'min-width:130px;',
              tdAttr: {style: 'vertical-align:middle;'},
              formatter: function (value) {
                return value || '-'
              }
            });
          }
        }
      }
      else {
        this.sheetOptionDetailMdl.isOpen = false;
        this.sheetOptionDetailMdl.data.list = [];
      }
      this.sheetOptionDetailMdl.isLoading = false;
    },
    /**
     * 전체선택 체크박스 클릭 이벤트
     * @param isSelected 선택여부
     */
    doItemAllCheck(isSelected) {
      if (isSelected || this.data.length === this.data.filter(e => e.selected).length) this.data.forEach(e => e.selected = isSelected);
    },
    /**
     * Row 선택 체크박스 클릭 이벤트
     * @param isSelected 선택여부
     */
    doItemCheck(isSelected) {
      if (!this.data.length || this.allSelected === isSelected || this.allSelected === this.data.every(e => e.selected)) return;
      this.allSelected = this.data.every(e => e.selected);
    },
    /**
     * 표준옵상세정보 보기 버튼 클릭
     */
    doOptionDetailShow(sizeSheetId) {
      this.sheetOptionDetailMdl.data.sizeSheetId = sizeSheetId;
      this.sheetOptionDetailMdl.isOpen = true;
    },
    /**
     * 사이즈조견표관리 해제 Process
     * @param sizeSheetId
     */
    async doDelete(sizeSheetId) {
      const res = await this.$api.postJson(`/meta/sheet/delete/${sizeSheetId}`);

      // 성공일 경우
      if (res.ok === 1) {
        await utils.alert(`사이즈 조견표가 해제되었습니다.`);
        await this.getList();
      }
    },
    /**
     * 사이즈조견표관리 등록/수정창 열기
     * @param type
     * @param sizeSheetId
     */
    doViewOpen(type, sizeSheetId) {
      this.$refs.sheetView.init(type, sizeSheetId);
    },
    /**
     * 사이즈조견표관리 등록 Callback
     */
    doSaveFinished() {
      this.getList(); // 리스트 조회
    },
    async getSearchOptionCategoryList() {
      const res = await this.$api.getJson(`/meta/optionCategory/search/group`);
      // 성공일 경우
      if (res.ok === 1) this.searchGroupList = res.data;
      else this.searchGroupList = [];
    },
    /**
     * 엑셀 다운로드 Process
     */
    async doExcelDown() {
      const selectList = [];

      this.data.forEach(item => {
        if (item.selected) selectList.push(item.id);
      });

      if (selectList.length === 0) {
        return utils.alert(`엑셀 다운로드하려는 항목을 선택해 주세요.`);
      }

      this.$refs.json_data.value = selectList.join(',');
      this.$refs.xlsx_form.submit();
    },
    setCommonSheet(item, type) {
      if (!item || type !== 'row') return;
      if (!item['brand_id']) {
        return 'br-grey-f0';
      }
    }
  }
}
</script>
<style scoped>
.mdl-use-body {
  min-height: 20px;
  max-height: 70vh;
  overflow: auto;
}

.no-data {
  text-align: center;
}

.mdl-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.setting-area {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.setting-area > button {
  width: 40px;
  margin: 0 3px;
}
.search-item-area {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.search-item {
  width: calc(100%/2 - 10px);
}

.btn-ext-area {
  display: flex;
  justify-content: right;
}

.search-area {
  min-height: 225px;
  margin-bottom: 15px;
}

.search-area > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn-option-view {
  margin-top: 10px;
}
</style>
