<template>
  <!-- 사용내역 모달 -->
  <b-modal
      :title="type === 'SAVE' ? '사이즈 조견표 추가' : '사이즈 조견표 수정'"
      size="lg"
      v-model="isOpen"
      ok-title="저장"
      cancel-title="닫기"
      :hide-footer="type === 'VIEW'"
      @ok.prevent="doSave"
  >
    <div class="mdl-size-sheet-body">
      <!-- 로딩바 -->
      <template v-if="isLoading.info">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-else>
        <div class="item-box">
          <div class="label-sm">사이즈 조견표 코드</div>
          <b-input v-model="data.code"
            placeholder="저장시 자동 생성 됩니다."
            disabled
          />
        </div>
        <div class="item-box">
          <div class="label-sm">브랜드 선택 <span class="required">*</span></div>
          <v-select
              autocomplete
              clearable
              v-model="selectBrandInfo"
              :options="searchBrandList"
              label="text"
              value="value"
              placeholder="브랜드를 선택해주세요."
              :disabled="type === 'VIEW'"
          />
        </div>
        <div class="item-box">
          <div class="label-sm">카테고리 선택 <span class="required">*</span></div>
          <v-select
              autocomplete
              clearable
              v-model="selectMetaOptionCategoryInfo"
              :options="searchGroupList"
              label="text"
              value="value"
              placeholder="카테고리를 선택해 주세요."
              :disabled="type !== 'SAVE'"
          />
        </div>
        <div class="item-box">
          <div class="label-sm">표준 옵션 선택 <span class="required">*</span></div>
          <b-table
              responsive
              show-empty
              outlined
              sticky-header="300px"
              :items="searchOption.list"
              :fields="searchOption.headers"
              :busy="isLoading.list">
            <!-- 선택 Row -->
            <template v-slot:cell(selected)="prop">
              <b-checkbox
                v-model="data.metaOptionIdList"
                :value="prop.item.id"
                :disabled="type === 'VIEW' || (selectMetaOptionCategoryInfo && selectMetaOptionCategoryInfo.metaOptionId === prop.item.id)"/>
            </template>
            <!-- 리스트 로딩바 -->
            <template v-slot:table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
          </b-table>
        </div>
        <template v-if="optionSizeMatchingList && Object.keys(optionSizeMatchingList).length > 0">
          <div class="item-box">
            <div class="label-sm">옵션 사이즈 매칭 <span class="required">*</span></div>
            <div class="option-size-match-area">
              <div class="row-option-area" v-for="(item, index) in optionSizeMatchingList" :key="index">
                <div class="row-option-title" v-html="item.header.isMaster ? `카테고리 대표 규격<br>${item.header.name}` : `${item.header.name}`"></div>
                <draggable :list="item.data" :group="item.data" handle=".btn-handle">
                  <div class="row-option-item" v-for="(subItem, subIndex) in item.data" :key="subIndex">
                    <div class="row-option-name">
                      <b-input
                        v-model="subItem.name"
                        disabled
                      />
                    </div>
                    <div class="row-option-btn">
                      <!-- 삭제 -->
                      <b-button class="btn-close" @click="doSizeDelete(subItem, subIndex, index)"><i class="fa fa-times-circle"/></b-button>
                      <!-- 복사 -->
                      <template v-if="!subItem.isCopy">
                        <b-button class="btn-copy" @click="doSizeCopy(subItem, subIndex, index)"><i class="fa fa-copy"/></b-button>
                      </template>
                    </div>
                    <div class="btn-handle-area">
                      <!-- 드래그앤드롭 -->
                      <b-button class="btn-handle"><i class="fa fa-bars"/></b-button>
                    </div>
                  </div>
                </draggable>
                <div class="btn-row-add-area">
                  <b-button variant="primary" class="btn-row-add" @click="doAdd(index)"><i class="fa fa-plus"/></b-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="item-box">
          <div class="label-sm">디폴트 마스터 규격 <span class="required">*</span></div>
          <template v-if="selectBrandInfo && selectMetaOptionCategoryInfo">
            <div class="default-option-area">
              <b-form-radio
                v-model="data.defaultMetaOptionId"
                v-for="(item) in defaultMetaOptionList"
                :key="item.id"
                :value="item.id"
                :disabled="type === 'VIEW'"
              >
                {{ item['public_unit_name'] }}
              </b-form-radio>
            </div>
          </template>
          <template v-else>
            브랜드와 카테고리를 먼저 선택해 주세요.
          </template>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import * as utils from '@/shared/utils';
export default {
  name: 'sheetView',
  data() {
    // 테이블 정보 설정
    const tblInfo = {
      tdAttr: {style: 'vertical-align:middle;'}
    };

    return {
      type: 'VIEW', // 타입 (VIEW: 상세 / MODIFY: 수정 / SAVE: 등록)
      isOpen: false, // 오픈여부
      metaSizeSheetId: null, // 사이즈조견표관리 고유번호
      searchBrandList: [], // 검색용 브랜드리스트
      searchGroupList: [], // 검색용 카테고리그룹리스트
      // 로딩바
      isLoading: {
        list: false,
        info: false,
        save: false
      },
      // 권한
      isAuth: {
        add: false // 추가 권한
      },
      data: {
        metaSizeSheetId: null,
        code: '', // 조견표코드
        brandId: null, // 브랜드번호
        metaOptionCategoryId: null, // 카테고리그룹
        metaOptionIdList: [], // 표준옵션 리스트
        defaultMetaOptionId: null, // 디폴트마스터옵션
      },
      searchOptionCategoryInfo: {},
      searchOption: {
        list: [],
        headers: [
          {key: 'selected', label: '', class: 'w-65px', tdAttr: tblInfo.tdAttr},
          {key: 'private_unit_name', label: '표준 옵션 단위 (내부용/파트너노출용)', class: 'text-center', sortable: true, thStyle: 'min-width:260px;', tdAttr: tblInfo.tdAttr},
          {key: 'public_unit_name', label: '표준 옵션 단위 (고객 노출용)', class: 'text-center', sortable: true, thStyle: 'min-width:200px;', tdAttr: tblInfo.tdAttr},
          {key: 'size_cnt', label: '표준 옵션 개수', class: 'text-center', sortable: false, thStyle: 'min-width:110px;', tdAttr: tblInfo.tdAttr},
          {key: 'size_names', label: '표준 옵션 상세 정보', class: '', sortable: false, thStyle: 'min-width:160px;text-align:center',
            tdAttr: {
              style: 'vertical-align:middle;max-width:500px;'
            }
          },
        ]
      },
      selectBrandInfo: null,
      selectMetaOptionCategoryInfo: null,
      defaultMetaOptionList: [],
      optionSizeMatchingList: {},
      delMetaOptionList: [],
      delSizeSheetOptionDetailList: []
    }
  },
  created() {
    // 쓰기 권한이 있을 경우
    if (this.$R('META_W')) {
      this.isAuth.add = true; // 표준옵션추가 권한
    }
  },
  watch: {
    isOpen() {
      // 모달 닫기
      if (this.isOpen === false) {
        this.doClose();
      }
    },
    'selectMetaOptionCategoryInfo'() {
      if (this.isLoading.info === false) {
        this.data.defaultMetaOptionId = this.selectMetaOptionCategoryInfo ? this.selectMetaOptionCategoryInfo.metaOptionId : null;
      }
      if (this.selectMetaOptionCategoryInfo && this.type === 'SAVE') {
        // 표준 옵션 default 설정
        this.defaultMetaOptionList = [];
        this.data.metaOptionIdList = [];
        this.data.metaOptionIdList.push(this.selectMetaOptionCategoryInfo.metaOptionId);
      }
    },
    'data.metaOptionIdList'(newItem, oldItem) {
      if (this.type === 'SAVE') {
        this.optionSizeMatchingList = {};
        this.defaultMetaOptionList = [];
        const optionSizeMatchingList = structuredClone(this.optionSizeMatchingList);
        const categoryMetaOptionIndex = this.selectMetaOptionCategoryInfo ? this.data.metaOptionIdList.indexOf(this.selectMetaOptionCategoryInfo.metaOptionId) : null;
        const metaOptionIdList = structuredClone(this.data.metaOptionIdList);

        if (categoryMetaOptionIndex > 0) {
          metaOptionIdList.splice(metaOptionIdList[categoryMetaOptionIndex], 0, metaOptionIdList[0]);
        }

        // 선택한 표준옵션 리스트
        metaOptionIdList.forEach(item => {
          // 표준옵션 검색 리스트
          this.searchOption.list.forEach(subItem => {
            if (subItem.id === item) {
              const isMaster = this.selectMetaOptionCategoryInfo && this.selectMetaOptionCategoryInfo.metaOptionId === item;
              this.defaultMetaOptionList.push(subItem);

              optionSizeMatchingList[`'${subItem.id}'`] = {
                header: {
                  metaSizeSheetOptionId: null,
                  metaOptionId: subItem.id,
                  name: subItem['public_unit_name'],
                  isMaster: isMaster
                },
                data: []
              };

              const sizeIds = subItem['size_ids'].split(',');
              const sizeNames = subItem['ori_size_names'].split(',');

              sizeIds.forEach((item, index) => {
                optionSizeMatchingList[`'${subItem.id}'`].data.push({
                  id: '',
                  metaSizeSheetOptionId: null,
                  metaOptionSizeId: item,
                  name: sizeNames[index],
                  isCopy: false
                });
              });
            }
          });
        });
        this.optionSizeMatchingList = optionSizeMatchingList;
      } else {
        const delMetaOptionIdList = oldItem.filter(id => !newItem.includes(id));
        const addMetaOptionIdList = newItem.filter(id => !oldItem.includes(id));
        const optionSizeMatchingList = structuredClone(this.optionSizeMatchingList);

        if (delMetaOptionIdList.length) {
          // 표준옵션 검색 리스트
          this.searchOption.list.forEach(subItem => {
            // 삭제할 데이터일 경우
            if (delMetaOptionIdList.includes(subItem.id)) {
              delete optionSizeMatchingList[`'${subItem.id}'`];

              if (this.optionSizeMatchingList[`'${subItem.id}'`].header.metaSizeSheetOptionId && this.optionSizeMatchingList[`'${subItem.id}'`].header.metaOptionId) {
                this.delMetaOptionList.push(subItem.id);
              }
            }
          });

          this.defaultMetaOptionList.forEach((subItem, subIndex) => {
            // 삭제할 데이터일 경우
            if (delMetaOptionIdList.includes(subItem.id)) {
              this.defaultMetaOptionList.splice(subIndex, 1);
            }
          });
        }

        if (addMetaOptionIdList.length) {
          // 표준옵션 검색 리스트
          this.searchOption.list.forEach(subItem => {
            // 추가할 데이터일 경우
            if (addMetaOptionIdList.includes(subItem.id)) {
              this.defaultMetaOptionList.push(subItem);

              optionSizeMatchingList[`'${subItem.id}'`] = {
                header: {
                  metaSizeSheetOptionId: null,
                  metaOptionId: subItem.id,
                  name: subItem['public_unit_name'],
                  isMaster: false
                },
                data: []
              };

              const sizeIds = subItem['size_ids'].split(',');
              const sizeNames = subItem['ori_size_names'].split(',');

              sizeIds.forEach((item, index) => {
                optionSizeMatchingList[`'${subItem.id}'`].data.push({
                  id: '',
                  metaSizeSheetOptionId: null,
                  metaOptionSizeId: item,
                  name: sizeNames[index],
                  isCopy: false
                });
              });
            }
          });
        }

        if (this.isLoading.info === false) this.optionSizeMatchingList = optionSizeMatchingList;
      }
    },
  },
  methods: {
    /**
     * 초기화
     * @param type 타입 (VIEW: 상세 / MODIFY: 수정 / SAVE: 등록)
     * @param metaSizeSheetId 사이즈조견표관리 고유번호
     */
    async init(type, metaSizeSheetId) {
      this.isOpen = true;

      if (type === 'VIEW') {
        this.type = this.isAuth ? 'MODIFY' : 'VIEW';
      } else {
        this.type = type;
      }
      this.metaSizeSheetId = metaSizeSheetId;

      this.isLoading.info = true;
      await this.getSearchBrandList(); // 브랜드검색 리스트 조회
      await this.getSearchOptionCategoryList(); // 카테고리그룹검색 리스트 조회
      await this.getOptionList();
      if (this.type !== 'SAVE' && this.metaSizeSheetId) await this.getInfo();
      this.isLoading.info = false;
    },
    /**
     * 상세 조회 Process
     */
    async getInfo() {
      // 상세정보 조회
      const res = await this.$api.getJson(`/meta/sheet/${this.metaSizeSheetId}`);

      // 성공일 경우
      if (res.ok === 1) {
        const info = res.data.info;

        this.data = {
          metaSizeSheetId: info.id,
          code: info.code, // 조견표코드
          brandId: null, // 브랜드번호
          metaOptionCategoryId: null, // 카테고리그룹
          metaOptionIdList: [], // 표준옵션 리스트
          defaultMetaOptionId: info['default_meta_option_id'], // 디폴트마스터옵션
        };

        this.selectBrandInfo = {
          value: info['brand_id'],
          text: info['brand_id'] ? `(${info['brand_id']}) ${info.brandnm} / ${info.brandnm_kr}` : `전체 브랜드`
        };

        this.selectMetaOptionCategoryInfo = {
          value: info['meta_option_category_id'],
          text: info['meta_option_category_name'],
          metaOptionId: info['category_meta_option_id']
        };

        this.searchOptionCategoryInfo = {
          metaOptionId: info['category_meta_option_id']
        };

        const optionSizeMatchingList = {}
        if (res.data.sizeSheetOptionList && res.data.sizeSheetOptionList.length > 0) {
          for (const optionInfo of res.data.sizeSheetOptionList) {
            optionSizeMatchingList[`'${optionInfo['meta_option_id']}'`] = {
              header: {
                metaSizeSheetOptionId: optionInfo['id'],
                metaOptionId: optionInfo['meta_option_id'],
                name: optionInfo['public_unit_name'],
                isMaster: info['category_meta_option_id'] === optionInfo['meta_option_id']
              },
              data: []
            };

            for (const optionDetailInfo of optionInfo.detailList) {
              const data = {
                id: optionDetailInfo.id,
                metaSizeSheetOptionId: optionInfo['id'],
                metaOptionSizeId: optionDetailInfo['meta_option_size_id'],
                name: optionDetailInfo.name || '-',
                isCopy: false,
              };
              optionSizeMatchingList[`'${optionInfo['meta_option_id']}'`].data.push(data);
            }
          }
        }

        this.data.metaOptionIdList = res.data.sizeSheetOptionList.map(e => e['meta_option_id']);
        this.optionSizeMatchingList = optionSizeMatchingList;
      }
      else {
        this.isOpen = false;
      }
    },
    /**
     * 모달 닫기 (초기화)
     */
    doClose() {
      // 데이터 초기화
      this.type = 'VIEW';
      this.metaSizeSheetId = null;

      this.selectBrandInfo = null;
      this.selectMetaOptionCategoryInfo = null;

      this.searchBrandList = [];
      this.searchGroupList = [];
      this.delMetaOptionList = [];
      this.delSizeSheetOptionDetailList = [];

      this.data = {
        metaSizeSheetId: null, // 사이즈조견표관리 고유번호
        code: '', // 조견표코드
        brandId: null, // 브랜드정보
        metaOptionCategoryId: null, // 카테고리그룹
        metaOptionIdList: [], // 표준옵션 리스트
        defaultMetaOptionId: null, // 디폴트마스터옵션
      };

      this.searchOptionCategoryInfo = {};
      this.searchOption.list = [];
      this.defaultMetaOptionList = [];
      this.optionSizeMatchingList = {};
    },
    /**
     * 사이즈조견표관리 등록/수정 Process
     */
    async doSave() {
      const isValid = await this.doValid(); // 유효성 체크
      if (!isValid) return false;

      const data = {
        brandId: this.selectBrandInfo.value, // 브랜드정보
        metaOptionCategoryId: this.selectMetaOptionCategoryInfo.value, // 카테고리그룹
        sizeSheetOptionList: [],
        defaultMetaOptionId: this.data.defaultMetaOptionId, // 디폴트마스터옵션
      };

      for (const key in this.optionSizeMatchingList) {
        const sizeData = {
          id: this.optionSizeMatchingList[key].header.metaSizeSheetOptionId || null,
          metaSizeSheetId: this.metaSizeSheetId || null,
          metaOptionId: this.optionSizeMatchingList[key].header.metaOptionId || null,
          detailList: []
        };

        this.optionSizeMatchingList[key].data.map((subItem, subIndex) => {
          const sizeDetailData = {
            id: subItem.id || null,
            metaSizeSheetOptionId: this.optionSizeMatchingList[key].header.metaSizeSheetOptionId || null,
            metaOptionSizeId: subItem.metaOptionSizeId,
            orderSeq: subIndex + 1
          };

          sizeData.detailList.push(sizeDetailData);
        });
        data.sizeSheetOptionList.push(sizeData);
      }

      if (this.type === 'MODIFY') {
        data['delMetaOptionList'] = this.delMetaOptionList;
        data['delSizeSheetOptionDetailList'] = this.delSizeSheetOptionDetailList;
      }

      const res = await this.$api.postJson(`${this.type === 'SAVE' ? '/meta/sheet/save' : '/meta/sheet/update/' + this.data.metaSizeSheetId}`, data);
      // 성공일 경우
      if (res.ok === 1) {
        const msg = this.type === 'SAVE' ? `사이즈 조견표 관리가 등록되었습니다.` : `사이즈 조견표 관리가 수정되었습니다.`;
        await utils.alert(msg);
        this.$emit('save');
        this.isOpen = false;
      }
    },
    /**
     * 유효성 체크
     */
    async doValid() {
      if (!this.selectBrandInfo) {
        alert('브랜드를 선택해주세요.');
        return false;
      }

      if (!this.selectMetaOptionCategoryInfo || !this.selectMetaOptionCategoryInfo.value) {
        alert('카테고리를 선택해주세요.');
        return false;
      }

      if (this.data.metaOptionIdList.length === 0) {
        alert('표준옵션을 선택해주세요.');
        return false;
      }

      const maxLengths = [];
      for (const key in this.optionSizeMatchingList) {
        maxLengths.push(this.optionSizeMatchingList[key].data.length);
      }
      const isMatch = new Set(maxLengths).size === 1;
      if (!isMatch) {
        alert('대표옵션과 표준옵션 추가 항목의 열과 행이 일치하지 않습니다.\n모든 옵션 사이즈의 값이 열과 행이 동일해야합니다.');
        return false;
      }

      if (!this.data.defaultMetaOptionId) {
        alert('디폴트 마스터 규격을 선택해주세요.');
        return false;
      }

      return true;
    },
    /**
     * 검색용 브랜드 리스트 조회 Process
     */
    async getSearchBrandList() {
      const res = await this.$api.getJson(`/meta/sheet/search/brand`);
      // 성공일 경우
      if (res.ok === 1) {
        this.searchBrandList = [
          {
            value: null,
            text: "전체 브랜드"
          },
          ...res.data
        ];
      }
      else this.searchBrandList = [];
    },
    /**
     * 검색용 카테고리 그룹 리스트 조회 Process
     */
    async getSearchOptionCategoryList() {
      const res = await this.$api.getJson(`/meta/optionCategory/search/group`);
      // 성공일 경우
      if (res.ok === 1) {
        this.searchGroupList = res.data;
      }
      else {
        this.searchGroupList = [];
      }
    },
    /**
     * 표준옵션 리스트 검색 Process
     */
    async getOptionList() {
      const res = await this.$api.getJson(`/meta/option`);
      if (res.ok === 1) this.searchOption.list = res.data;
      else this.searchOption.list = [];
    },
    /**
     * 옵션 사이즈 매칭 복사 기능
     */
    doSizeCopy(item, index, parentIndex) {
      const data = {
        id: null,
        metaSizeSheetOptionId: item.metaSizeSheetOptionId,
        metaOptionSizeId: item.metaOptionSizeId,
        name: item.name,
        isCopy: true
      };

      const copyList = structuredClone(this.optionSizeMatchingList);
      copyList[parentIndex].data.splice(index + 1, 0, data);
      this.optionSizeMatchingList = copyList;
    },
    /**
     * 옵션 사이즈 제거
     */
    doSizeDelete(item, index, parentIndex) {
      const realSizeList = [];
      const copyList = structuredClone(this.optionSizeMatchingList);
      copyList[parentIndex].data.forEach(function (item) {
        if (!item.isCopy && item.metaOptionSizeId) realSizeList.push(item);
      });

      if (!item.isCopy) {
        if (item.metaOptionSizeId && realSizeList.length === 1) {
          alert('표준 옵션 사이즈는 최소 1개 이상 포함되어야 합니다.');
          return false;
        }

        if (item.id) {
          this.delSizeSheetOptionDetailList.push(item.id);
          copyList[parentIndex].data.splice(index, 1);
        } else {
          copyList[parentIndex].data = copyList[parentIndex].data.filter(e => e.metaOptionSizeId !== item.metaOptionSizeId).map(e => e);
        }
      } else {
        copyList[parentIndex].data.splice(index, 1);
      }

      this.optionSizeMatchingList = copyList;
    },
    /**
     * 옵션 사이즈 (-) 추가
     */
    doAdd(parentIndex) {
      const data = {
        id: '',
        metaSizeSheetOptionId: null,
        metaOptionSizeId: null,
        name: '-',
        isCopy: true
      };

      const copyList = structuredClone(this.optionSizeMatchingList);
      copyList[parentIndex].data.push(data);
      this.optionSizeMatchingList = copyList;
    }
  }
}
</script>
<style scoped>
ul {
  padding: 0 1rem;
}
.item-box:not(:last-child) {
  margin-bottom: 20px;
}

.size-item-box input {
  min-width: 150px;
}

.size-item-box button {
  width: 35px;
  height: 35px;
}

.search-option-area > input {
  width: calc(100% - 60px);
}

.option-size-match-area {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  border: 1px solid #f0f0f0;
}
.row-option-area {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 200px;
}

.row-option-area:not(:last-child) {
  margin-right: 20px;
}

.row-option-title {
  font-size: 14px;
  font-weight: 600;
  min-height: 50px;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 15px;
  padding: 0 10px 5px 10px;
}

.row-option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 10px;
}

.row-option-name {
  width: calc(100% - 40px);
}

.row-option-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 35px;
}

.row-option-btn > div {
  height: 16px;
}

.mdl-size-sheet-body {
  max-height: 70vh;
  overflow: auto;
  padding: 0 10px;
}

.btn-copy i {
  font-size: 10px;
}

.default-option-area {
  display: flex;
}

.default-option-area > div:not(:last-child) {
  margin-right: 10px;
}

.btn-close, .btn-copy {
  padding: 0;
  background: unset;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.btn-close:active, .btn-copy:active, .btn-handle:active {
  background: inherit !important;
  border-color: unset !important;
}

.btn-close:focus, .btn-copy:focus, .btn-handle:focus {
  box-shadow: none !important;
}

.btn-copy {
  background: #f0f0f0;
  border-radius: 50%;
  width: 14px;
  padding: 3px;
}

.btn-row-add-area {
  padding: 0 10px 15px 10px;
}
.btn-row-add-area > button {
  width: 100%;
}
.btn-handle-area {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-handle {
  background: #fff;
  border: 0;
  width: 20px;
  height: 20px;
  padding: 0;
}
</style>
