<!-- 발란옵션관리 메인 -->
<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="표준 옵션 관리">
        <meta-option/>
      </b-tab>
      <b-tab title="카테고리 대표 규격 관리">
        <meta-option-category/>
      </b-tab>
      <b-tab title="사이즈 조견표 관리">
        <meta-sheet/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import metaOption from '@/views/meta/MetaOption.vue'
import metaOptionCategory from '@/views/meta/MetaOptionCategory.vue'
import metaSheet from '@/views/meta/MetaSheet.vue'

export default {
  name: "MetaOptionMng",
  title: '발란 옵션 관리',
  components: {metaOption, metaOptionCategory, metaSheet},
  data() {
    return {
      tabIndex: 0,
    }
  }
}
</script>
