<template>
  <!-- 사용내역 모달 -->
  <b-modal
      :title="type === 'SAVE' ? '카테고리 대표 규격 설정' : '카테고리 대표 규격 수정'"
      size="lg"
      v-model="isOpen"
      ok-title="저장"
      cancel-title="닫기"
      :hide-footer="type === 'VIEW'"
      @ok.prevent="doSave"
  >
  <div class="mdl-use-body">
    <!-- 로딩바 -->
    <template v-if="isLoading.info">
      <div class="text-center text-info my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </template>
    <template v-else>
      <div>
        <ul>
          <li class="required">카테고리 대표 규격은 카테고리 그룹 단위로만 설정할 수 있습니다.</li>
          <li class="required">대표 규격 설정이 필요한 카테고리는 [상세] 버튼을 클릭하여 기존 그룹에 추가 하거나, [추가] 버튼을 클릭하여 신규 등록할 수 있습니다.</li>
        </ul>
      </div>
      <div class="item-box">
        <div class="label-sm">그룹명 <span class="required">*</span></div>
        <b-input v-model="data.name"
          placeholder="그룹명을 입력해주세요."
          :disabled="type === 'VIEW'"
        />
      </div>
      <div class="item-box">
        <div class="label-sm">카테고리 선택 <span class="required">*</span></div>
        <v-select
            multiple
            autocomplete
            clearable
            :closeOnSelect="false"
            v-model="selectCategoryList"
            :options="searchCategoryList"
            label="text"
            value="value"
            :reduce="country => country.value"
            :placeholder="searchCategoryList && searchCategoryList.length ? `카테고리를 선택해주세요.` : '로딩중...'"
            :disabled="type === 'VIEW'"
        />
      </div>
      <div class="item-box">
        <div class="label-sm">표준 옵션값 <span class="required">*</span></div>
        <div class="search-option-area" v-if="type === 'SAVE'">
          <b-input v-model="searchOptionParams.keyword"
           placeholder="표준 옵션값 기준으로 검색 해주세요."
           @focus="doRepInput($event)"
           @blur="doRepInput($event)"
           @keydown="doRepInput($event)"
           @keyup="doRepInput($event)"
          />
          <b-button class="mr-1" variant="dark" @click="getOptionList" :disabled="isLoading.list">
            검색
            <b-spinner class="ml-1" small v-if="isLoading.list"/>
          </b-button>
        </div>

        <b-table
          responsive
          show-empty
          outlined
          sticky-header="370px"
          :items="searchOptionList"
          :fields="headers"
          :busy="isLoading.list">
          <!-- 선택 Row -->
          <template v-slot:cell(selected)="prop">
            <b-form-radio v-model="selectOptionId" :value="prop.item.id" :disabled="type !== 'SAVE'"/>
          </template>
          <!-- 리스트 로딩바 -->
          <template v-slot:table-busy>
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </b-table>
      </div>
    </template>
  </div>
  </b-modal>
</template>

<script>
import * as utils from '@/shared/utils';
export default {
  name: 'optionCategoryView',
  data() {
    // 테이블 정보 설정
    const tblInfo = {
      tdAttr: {style: 'vertical-align:middle;'}
    };

    return {
      type: 'VIEW', // 타입 (VIEW: 상세 / MODIFY: 수정 / SAVE: 등록)
      isOpen: false, // 오픈여부
      metaOptionCategoryId: null, // 카테고리대표규격관리 고유번호
      selectOptionId: null,
      selectCategoryList: [],
      // 로딩바
      isLoading: {
        list: false,
        info: false,
        save: false
      },
      // 권한
      isAuth: {
        add: false // 추가 권한
      },
      data: {
        name: '',
        categoryList: [],
        metaOptionId: null,
        metaOptionCategoryId: null,
      },
      searchCategoryList: [],
      searchOptionList: [],
      searchOptionParams: {
        keyword: '', // 검색어
        status: '' // 사용여부
      },
      headers: [
        {key: 'selected', label: '', class: 'w-65px', tdAttr: tblInfo.tdAttr},
        {key: 'private_unit_name', label: '표준 옵션 단위 (내부용/파트너노출용)', class: 'text-center', sortable: false, thStyle: 'min-width:260px;', tdAttr: tblInfo.tdAttr},
        {key: 'public_unit_name', label: '표준 옵션 단위 (고객 노출용)', class: 'text-center', sortable: false, thStyle: 'min-width:200px;', tdAttr: tblInfo.tdAttr},
        {key: 'size_cnt', label: '표준 옵션 개수', class: 'text-center', sortable: false, thStyle: 'min-width:110px;', tdAttr: tblInfo.tdAttr},
        {key: 'size_names', label: '표준 옵션 상세 정보', class: '', sortable: false, thStyle: 'min-width:160px;text-align:center;',
          tdAttr: {
            style: 'vertical-align:middle;max-width:500px;'
          }
        },
      ]
    }
  },
  created() {
    // 쓰기 권한이 있을 경우
    if (this.$R('META_W')) {
      this.isAuth.add = true; // 표준옵션추가 권한
    }
  },
  watch: {
    isOpen() {
      // 모달 닫기
      if (this.isOpen === false) {
        this.doClose();
      }
    }
  },
  methods: {
    /**
     * 초기화
     * @param type 타입 (VIEW: 상세 / MODIFY: 수정 / SAVE: 등록)
     * @param metaOptionCategoryId 카테고리대표규격관리 고유번호
     */
    async init(type, metaOptionCategoryId) {
      this.isOpen = true;

      if (type === 'VIEW') {
        this.type = this.isAuth ? 'MODIFY' : 'VIEW';
      } else {
        this.type = type;
      }
      this.metaOptionCategoryId = metaOptionCategoryId;
      await this.getSearchCategoryList(); // 카테고리검색 리스트 조회
      await this.getOptionList();
      if (this.type !== 'SAVE' && this.metaOptionCategoryId) await this.getInfo();
    },
    /**
     * 상세 조회 Process
     */
    async getInfo() {
      this.isLoading.info = true;
      // 상세정보 조회
      const res = await this.$api.getJson(`/meta/optionCategory/${this.metaOptionCategoryId}`);

      // 성공일 경우
      if (res.ok === 1) {
        this.data = {
          name: res.data.name,
          categoryList: res.data.categoryList,
          metaOptionId: res.data.metaOptionId,
          metaOptionCategoryId: res.data.id
        };
        for (const item of res.data.categoryList) {
          this.searchCategoryList.push(item);
        }

        this.selectOptionId = this.data.metaOptionId;
        this.selectCategoryList = this.data.categoryList;

        if (this.type !== 'SAVE') {
          const searchOptionList = [];
          this.searchOptionList.forEach(item => {
            if (item.id === this.selectOptionId) searchOptionList.push(item);
          });
          this.searchOptionList = searchOptionList;
        }
      }
      else {
        this.isOpen = false;
      }
      this.isLoading.info = false;
    },
    /**
     * 모달 닫기 (초기화)
     */
    doClose() {
      // 데이터 초기화
      this.type = 'VIEW';
      this.metaOptionCategoryId = null;
      this.selectOptionId = null;
      this.selectCategoryList = [];
      this.searchOptionParams.keyword = '';
      this.data = {
        name: '',
        categoryList: [],
        metaOptionId: null,
        metaOptionCategoryId: null
      };
    },
    /**
     * Input 입력가능문자열 체크 및 제거
     * @param e
     */
    doRepInput(e) {
      const val = e.target.value;
      let regex = /[^a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣_]/gi;
      if (val) e.target.value = val.toString().replace(regex, '').toUpperCase();
    },
    /**
     * 카테고리대표규격 등록/수정 Process
     */
    async doSave() {
      const isValid = await this.doValid(); // 유효성 체크
      if (!isValid) return false;
      this.data.metaOptionId = this.selectOptionId;
      this.data.categoryList = this.selectCategoryList.map(e => e.value);
      const res = await this.$api.postJson(`${this.type === 'SAVE' ? '/meta/optionCategory/save' : '/meta/optionCategory/update/' + this.data.metaOptionCategoryId}`, this.data);
      // 성공일 경우
      if (res.ok === 1) {
        const msg = this.type === 'SAVE' ? `카테고리 그룹이 등록되었습니다.` : `카테고리 그룹이 수정되었습니다.`;
        await utils.alert(msg);
        this.$emit('save');
        this.isOpen = false;
      }
    },
    /**
     * 유효성 체크
     */
    async doValid() {
      const selectCategoryList = this.selectCategoryList.map(e => e.value);
      if (!this.data.name) {
        alert('그룹명을 입력해주세요.');
        return false;
      }

      if (selectCategoryList.length === 0) {
        alert('1개 이상의 카테고리를 선택해 주세요.');
        return false;
      }

      if (!this.selectOptionId) {
        alert('옵션을 선택해주세요.');
        return false;
      }
      return true;
    },
    async getSearchCategoryList() {
      const res = await this.$api.getJson(`/meta/optionCategory/search/category?isNotExist=true`);
      // 성공일 경우
      if (res.ok === 1) this.searchCategoryList = res.data;
      else this.searchCategoryList = [];
    },
    async getOptionList() {
      this.selectOptionId = null;
      const params = new URLSearchParams(this.searchOptionParams);
      const res = await this.$api.getJson(`/meta/option?${params.toString()}`);
      if (res.ok === 1) this.searchOptionList = res.data;
      else this.searchOptionList = [];
    }
  }
}
</script>
<style scoped>
  ul {
    padding: 0 1rem;
  }
  .item-box:not(:last-child) {
    margin-bottom: 20px;
  }

  .size-item-box input {
    min-width: 150px;
  }

  .size-item-box button {
    width: 35px;
    height: 35px;
  }

  .search-option-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .search-option-area > input {
    width: calc(100% - 60px);
  }
</style>
