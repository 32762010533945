<template>
  <div>
    <!-- 검색영역 -->
    <b-input-group class="mb-2">
      <!-- 검색버튼 -->
      <b-input-group-prepend>
        <b-button variant="primary" @click="getList" :disabled="isLoading.list">
          <i class="fa fa-search"/> 검색
          <b-spinner class="mr-1" small v-if="isLoading.list"/>
        </b-button>
      </b-input-group-prepend>
      <!-- 검색어 -->
      <b-form-input type="text" placeholder="검색어를 입력해주세요."
        v-model="searchParams.keyword"
        @keyup.enter.prevent.stop="getList"
        v-focus/>
    </b-input-group>
    <b-row class="mb-2">
      <!-- 사용여부 -->
      <b-col md="12">
        <div class="pt-2">
          <small>사용여부</small>
          <b-form-radio-group class="col-form-label"
            v-model="searchParams.status"
            :options="selectStatusList"/>
        </div>
      </b-col>
    </b-row>
    <!-- 하단 버튼영역 -->
    <div class="">
      <b-button class="mr-1" variant="primary" @click="getList" :disabled="isLoading.list">
        검색
        <b-spinner class="ml-1" small v-if="isLoading.list"/>
      </b-button>
      <b-button
        v-if="isAuth.add"
        variant="success"
        @click="doViewOpen('SAVE')"
      >
        추가
      </b-button>
    </div>
    <hr/>

    <!-- 검색 리스트 -->
    <b-card>
      <slot name="header"></slot>
      <b-row class="form-group">
        <b-col cols="10" class="col-form-label">
          <div class="d-inline" v-html="data.length + ' 개'"></div>
        </b-col>
        <b-col cols="2" class="clearfix">
          <b-form inline class="pull-right">
            <b-form-select :options="pagination.perPageList" v-model="pagination.perPage"></b-form-select>
          </b-form>
        </b-col>
      </b-row>

      <b-table
          responsive
          show-empty
          :items="data"
          :fields="headers"
          :per-page="pagination.perPage"
          :current-page="pagination.currentPage"
          :sticky-header="false"
          :busy="isLoading.list">
        <!-- 전체선택 Header -->
        <template v-slot:head(selected)>
          <b-form-checkbox v-model="allSelected" @input="doItemAllCheck"></b-form-checkbox>
        </template>
        <!-- 전체선택 Row -->
        <template v-slot:cell(selected)="data">
          <b-form-checkbox :id="tblId + '_checkbox'+data.index" v-model="data.item.selected" @input="doItemCheck"></b-form-checkbox>
        </template>
        <!-- 사용내역 > 보기 Row -->
        <template v-slot:cell(use_more)="data">
          <template v-if="data.item.status === 'USED'">
            <b-button size="sm" variant="outline-primary"  @click="doUseMoreShow(data.item.id)">보기</b-button>
          </template>
          <template v-else>-</template>
        </template>
        <!-- 관리 Row -->
        <template v-slot:cell(setting)="data">
          <div class="setting-area">
            <b-button size="sm" @click="doViewOpen('VIEW', data.item.id)">상세</b-button>
            <template v-if="isAuth.add">
              <b-button size="sm" variant="danger" @click="doDelete(data.item.id)">삭제</b-button>
            </template>
          </div>
        </template>
        <!-- 리스트 로딩바 -->
        <template v-slot:table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
      <!-- 테이블 하단 영역 -->
      <b-row>
        <b-col lg="10">
          <div class="clearfix">
            <nav class="pull-left">
              <b-pagination :total-rows="data.length" :per-page="pagination.perPage" :limit="11" v-model="pagination.currentPage" prev-text="Prev" next-text="Next"/>
            </nav>
          </div>
        </b-col>
        <b-col class="d-none d-lg-block clearfix" lg="2">
          <b-form inline class="pull-right">
            <b-form-select :options="pagination.perPageList" v-model="pagination.perPage"></b-form-select>
          </b-form>
        </b-col>
      </b-row>
      <slot name="footer"></slot>
    </b-card>

    <!-- 사용내역 모달 -->
    <b-modal
      title="표준 옵션 적용 여부"
      size="lg"
      modal-class="modal-fullscreen"
      hide-footer
      v-model="useMoreMdl.isOpen"
    >
      <div class="mdl-use-body">
        <!-- 로딩바 -->
        <template v-if="useMoreMdl.isLoading">
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template v-else>
          <!-- 데이터가 존재하지 않을 경우 -->
          <template v-if="useMoreMdl.data.optionCategoryList.length === 0 && useMoreMdl.data.sheetList.length === 0">
            <div class="no-data">검색 데이터가 존재하지 않습니다.</div>
          </template>
          <!-- 조견표 데이터가 있을 경우 -->
          <template v-if="useMoreMdl.data.sheetList.length > 0">
            <div class="mdl-title">조견표 리스트</div>
            <b-table
                responsive
                outlined
                sticky-header="250px"
                :items="useMoreMdl.data.sheetList"
                :fields="useMoreMdl.sheetTable.headers">
            </b-table>
          </template>
          <!-- 카테고리대표규격관리 데이터가 있을 경우 -->
          <template v-if="useMoreMdl.data.optionCategoryList.length > 0">
            <div class="mdl-title">카테고리 대표 규격 리스트</div>
            <b-table
                responsive
                outlined
                sticky-header="250px"
                :items="useMoreMdl.data.optionCategoryList"
                :fields="useMoreMdl.optionCategoryTable.headers">
              <!-- 카테고리 Row -->
              <template v-slot:cell(category_code_path_list)="data">
                <div v-html="data.item.category_code_path_list.join(`<br>`)"/>
              </template>
              <!-- 카테고리코드 Row -->
              <template v-slot:cell(category_code_list)="data">
                <div v-html="data.item.category_code_list.join(`<br>`)"/>
              </template>
            </b-table>
          </template>
        </template>
      </div>
    </b-modal>

    <!-- 표준옵션관리 등록/수정 모달 -->
    <option-view ref="optionView" @save="doSaveFinished"></option-view>
  </div>
</template>

<script>
import * as utils from '@/shared/utils';
import OptionView from '@/views/modal/meta/OptionView.vue';
export default {
  name: "MetaOption",
  components: {OptionView},
  data() {
    // 테이블 정보 설정
    const tblInfo = {
      tdAttr: {style: 'vertical-align:middle;'}
    };

    return {
      tblId: null, // 테이블 ID
      searchParams: {
        keyword: '', // 검색어
        status: 'USED' // 사용여부
      },
      // 사용여부 Select List
      selectStatusList: [
        {text: '전체', value: ''},
        {text: '사용함', value: 'USED'},
        {text: '사용 안 함', value: 'NOT_USED'}
      ],
      // 로딩바
      isLoading: {
        list: false
      },
      // 권한
      isAuth: {
        add: false // 추가 권한
      },
      headers: [
        {key: 'id', label: 'No', class: 'text-center', sortable: false, thStyle: 'min-width:80px;', tdAttr: tblInfo.tdAttr},
        {key: 'private_unit_name', label: '표준 옵션 단위 (내부용/파트너노출용)', class: 'text-center', sortable: false, thStyle: 'min-width:280px;', tdAttr: tblInfo.tdAttr},
        {key: 'public_unit_name', label: '표준 옵션 단위 (고객 노출용)', class: 'text-center', sortable: false, thStyle: 'min-width:200px;', tdAttr: tblInfo.tdAttr},
        {key: 'size_cnt', label: '표준 옵션 개수', class: 'text-center', sortable: false, thStyle: 'min-width:110px;', tdAttr: tblInfo.tdAttr},
        {key: 'size_names', label: '표준 옵션 상세 정보', class: '', sortable: false, thStyle: 'min-width:160px;text-align:center;',
          tdAttr: {
            style: 'vertical-align:middle;max-width:500px;'
          }
        },
        {key: 'status', label: '사용 여부', class: 'text-center', sortable: false, thStyle: 'min-width:80px;', tdAttr: tblInfo.tdAttr,
          formatter: function (value) {
            return value === 'USED' ? '사용함' : '사용 안 함';
          }
        },
        {key: 'use_more', label: '사용 내역', class: 'text-center', sortable: false, thStyle: 'min-width:80px;', tdAttr: tblInfo.tdAttr},
        {key: 'created_at', label: '최초 생성 일시', class: 'text-center w-250px', sortable: false, thStyle: 'min-width:170px;', tdAttr: tblInfo.tdAttr},
        {key: 'updated_at', label: '최근 수정 일시', class: 'text-center w-250px', sortable: false, thStyle: 'min-width:170px;', tdAttr: tblInfo.tdAttr,
          formatter: function (value) {
            return value || '-';
          }
        },
        {key: 'setting', label: '관리', class: 'text-center', sortable: false, thStyle: 'min-width:80px;', tdAttr: tblInfo.tdAttr},
      ],
      data: [], // 리스트 데이터
      // 페이징 관련
      pagination: {
        perPage: 20, // Page 수
        perPageList: [10,20,30,40,50,100,200,500,1000], // Page 수 리스트
        currentPage: 1, // 현재 페이지
      },
      allSelected: false, // 전체선택여부
      // 사용내역 정보
      useMoreMdl: {
        isOpen: false, // 모달 Open 여부
        isLoading: false, // 로딩여부
        data: {
          metaOptionId: null, // 표준옵션관리 고유번호
          sheetList: [], // 조견표 리스트
          optionCategoryList: [] // 카테고리대표규격 리스트
        },
        // 카테고리대표규격 리스트 컬럼정보
        optionCategoryTable: {
          headers: [
            {key: 'name', label: '그룹명', class: 'text-center', thStyle: 'min-width:130px;', tdAttr: tblInfo.tdAttr},
            {key: 'category_code_list', label: '카테고리 코드', class: '', thStyle: 'min-width:190px;', tdAttr: tblInfo.tdAttr},
            {key: 'category_code_path_list', label: '카테고리 경로', class: '', thStyle: 'min-width:350px;', tdAttr: tblInfo.tdAttr}
          ]
        },
        // 조견표 리스트 컬럼정보
        sheetTable: {
          headers: [
            {key: 'brand_id', label: '브랜드번호', class: 'text-center', thStyle: 'min-width:100px;', tdAttr: tblInfo.tdAttr,
              formatter: function (value) {
                return value || '-';
              }
            },
            {key: 'brandnm', label: '브랜드명(영문)', class: '', thStyle: 'min-width:120px;text-align:center;', tdAttr: tblInfo.tdAttr,
              formatter: function (value) {
                return value || '-';
              }
            },
            {key: 'brandnm_kr', label: '브랜드명(한글)', class: '', thStyle: 'min-width:120px;text-align:center;', tdAttr: tblInfo.tdAttr,
              formatter: function (value) {
                return value || '-';
              }
            },
            {key: 'brand_type_name', label: '브랜드 속성', class: 'text-center', thStyle: 'min-width:100px;', tdAttr: tblInfo.tdAttr,
              formatter: function (value) {
                return value || '-';
              }
            },
            {key: 'category_code', label: '카테고리 코드', class: 'text-center', thStyle: 'min-width:150px;', tdAttr: tblInfo.tdAttr},
            {key: 'category_code_path', label: '카테고리 경로', class: 'text-center', thStyle: 'min-width:350px;', tdAttr: tblInfo.tdAttr}
          ]
        }
      }
    };
  },
  created() {
    this.tblId = this.tblId || 'tbl_' + (Math.random() + '').slice(2, 10); // 테이블 아이디
    // 쓰기 권한이 있을 경우
    if (this.$R('META_W')) {
      this.isAuth.add = true; // 표준옵션추가 권한
    }
    this.getList(); // 리스트 조회
  },
  watch: {
    "useMoreMdl.isOpen": function () {
      // 사용내역 Open
      if (this.useMoreMdl.isOpen) {
        this.getUseMoreList(); // 사용내역 리스트 조회
      }
      // 사용내역 Close
      else {
        this.useMoreMdl.data.metaOptionId = null;
        this.useMoreMdl.data.sheetList = [];
        this.useMoreMdl.data.optionCategoryList = [];
      }
    }
  },
  methods: {
    /**
     * 표준옵션관리 리스트 조회 Process
     */
    async getList() {
      const params = new URLSearchParams(this.searchParams);
      const res = await this.$api.getJson(`/meta/option?${params.toString()}`);
      if (res.ok === 1) this.data = res.data;
      else this.data = [];
    },
    /**
     * 사용내역 리스트 조회 Process
     */
    async getUseMoreList() {
      this.useMoreMdl.isLoading = true;
      const res = await this.$api.getJson(`/meta/option/use/${this.useMoreMdl.data.metaOptionId}`);

      // 성공일 경우
      if (res.ok === 1) {
        this.useMoreMdl.data.optionCategoryList = res.data.optionCategoryList;
        this.useMoreMdl.data.sheetList = res.data.sheetList;
      }
      else {
        this.useMoreMdl.isOpen = false;
        this.useMoreMdl.data.optionCategoryList = [];
        this.useMoreMdl.data.sheetList = [];
      }
      this.useMoreMdl.isLoading = false;
    },
    /**
     * 전체선택 체크박스 클릭 이벤트
     * @param isSelected 선택여부
     */
    doItemAllCheck(isSelected) {
      if (isSelected || this.data.length === this.data.filter(e => e.selected).length) this.data.forEach(e => e.selected = isSelected);
    },
    /**
     * Row 선택 체크박스 클릭 이벤트
     * @param isSelected 선택여부
     */
    doItemCheck(isSelected) {
      if (!this.data.length || this.allSelected === isSelected || this.allSelected === this.data.every(e => e.selected)) return;
      this.allSelected = this.data.every(e => e.selected);
    },
    /**
     * 사용내역 보기 버튼 클릭
     */
    doUseMoreShow(metaOptionId) {
      this.useMoreMdl.data.metaOptionId = metaOptionId;
      this.useMoreMdl.isOpen = true;
    },
    /**
     * 표준옵션관리 삭제 Process
     * @param metaOptionId
     */
    async doDelete(metaOptionId) {
      const res = await this.$api.postJson(`/meta/option/delete/${metaOptionId}`);

      // 성공일 경우
      if (res.ok === 1) {
        await utils.alert(`표준 옵션이 삭제되었습니다.`)
        await this.getList();
      }
    },
    /**
     * 표준옵션관리 등록/수정창 열기
     * @param type
     * @param metaOptionId
     */
    doViewOpen(type, metaOptionId) {
      this.$refs.optionView.init(type, metaOptionId);
    },
    /**
     * 표준옵션관리 등록 Callback
     */
    doSaveFinished() {
      this.getList(); // 리스트 조회
    }
  }
}
</script>
<style scoped>
.mdl-use-body {
  min-height: 20px;
  max-height: 70vh;
  overflow: auto;
}

.no-data {
  text-align: center;
}

.mdl-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.setting-area {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.setting-area > button {
  width: 40px;
  margin: 0 3px;
}
</style>
