<template>
  <!-- 사용내역 모달 -->
  <b-modal
      :title="type === 'SAVE' ? '표준 옵션 추가' : '표준 옵션 수정'"
      size="lg"
      v-model="isOpen"
      ok-title="저장"
      cancel-title="닫기"
      :hide-footer="type === 'VIEW'"
      @ok.prevent="doSave"
  >
  <div class="mdl-use-body">
    <!-- 로딩바 -->
    <template v-if="isLoading.info">
      <div class="text-center text-info my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </template>
    <template v-else>
      <div class="item-box">
        <div class="label-sm">표준 옵션 코드</div>
        <b-input v-model="data.code"
          placeholder="저장시 자동 생성 됩니다."
          disabled
        />
      </div>
      <div class="item-box">
        <div class="label-sm">표준 옵션 단위 (내부용/파트너노출용) <span class="required">*</span></div>
        <b-input v-model="data.privateUnitName"
          placeholder="옵션 단위 입력시 발란몰에 노출 되지 않습니다."
          :disabled="type === 'VIEW'"
          @focus="doRepInput($event, 'name')"
          @blur="doRepInput($event, 'name')"
          @keydown="doRepInput($event, 'name')"
          @keyup="doRepInput($event, 'name')"
        />
      </div>
      <div class="item-box">
        <div class="label-sm">표준 옵션 단위 (고객 노출용) <span class="required">*</span></div>
        <b-input v-model="data.publicUnitName"
          placeholder="옵션 단위를 입력해주세요 (ex. IT, KR, FR 과 같이 영문대문자 형식/한글 입력 가능)"
          :disabled="type === 'VIEW'"
          @focus="doRepInput($event, 'name')"
          @blur="doRepInput($event, 'name')"
          @keydown="doRepInput($event, 'name')"
          @keyup="doRepInput($event, 'name')"
        />
      </div>
      <div class="item-box">
        <div class="label-sm">표준 옵션 상세 정보 <span class="required">*</span></div>
        <div class="option-size-area">
          <div class="size-item-box" v-for="(item , index) in data.optionSizeList" :key="index">
            <div class="size-index">{{ item.orderSeq }}</div>
            <div>
              <b-input v-model="item.name"
                placeholder="ex. 38"
                :disabled="type === 'VIEW'"
                @focus="doRepInput($event, 'size')"
                @blur="doRepInput($event, 'size')"
                @keydown="doRepInput($event, 'size')"
                @keyup="doRepInput($event, 'size')"
              />
            </div>
            <!-- 등록일 경우 -->
            <template v-if="type === 'SAVE'">
              <!-- 버튼 영역 -->
              <div class="btn-size-area">
                <!-- 위로 이동 버튼 -->
                <b-button class="mr-1" variant="light" @click="moveOption(index, -1)"
                  :disabled="data.optionSizeList.length === 1 || index === data.optionSizeList.length - 1"
                >
                  <i class="fa fa-arrow-down"/>
                </b-button>
                <!-- 아래로 이동 버튼 -->
                <b-button class="mr-1" variant="light" @click="moveOption(index, 1)"
                  :disabled="data.optionSizeList.length === 1 || index === 0"
                >
                  <i class="fa fa-arrow-up"/>
                </b-button>
                <!-- 삭제 버튼 -->
                <b-button
                  class="mr-1"
                  variant="danger"
                  @click="removeOption(index)"
                  :disabled="data.optionSizeList.length === 1"
                >
                  <i class="fa fa-trash"/>
                </b-button>
                <template v-if="index === data.optionSizeList.length - 1">
                  <!-- 추가 버튼 -->
                  <b-button variant="primary" @click="addOption"><i class="fa fa-plus"/></b-button>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
  </b-modal>
</template>

<script>
import * as utils from '@/shared/utils';
export default {
  name: 'optionView',
  data() {
    const optionSizeList = [];
    // 표준옵션상세정보 기본 5개
    for (let i = 0; i < 5; i++) {
      optionSizeList.push({
        id: '',
        name: '',
        orderSeq: i+1
      });
    }

    return {
      type: 'VIEW', // 타입 (VIEW: 상세 / SAVE: 등록)
      isOpen: false, // 오픈여부
      metaOptionId: null, // 표준옵션관리 고유번호
      // 로딩바
      isLoading: {
        info: false,
        save: false
      },
      // 권한
      isAuth: {
        add: false // 추가 권한
      },
      data: {
        code: '', // 표준옵션코드
        privateUnitName: '', // 표준옵션단위(내부용/파트너 노출용)
        publicUnitName: '', // 표준옵션단위(고객 노출용)
        optionSizeList: optionSizeList // 표준옵션상세정보(리스트)
      },
    }
  },
  created() {
    // 쓰기 권한이 있을 경우
    if (this.$R('META_W')) {
      this.isAuth.add = true; // 표준옵션추가 권한
    }
  },
  watch: {
    isOpen() {
      // 모달 닫기
      if (this.isOpen === false) {
        this.doClose();
      }
    }
  },
  methods: {
    /**
     * 초기화
     * @param type 타입 (VIEW: 상세 / SAVE: 등록)
     * @param metaOptionId 표준옵션관리 고유번호
     */
    init(type, metaOptionId) {
      this.isOpen = true;
      this.type = type;
      this.metaOptionId = metaOptionId;

      if (this.type === 'VIEW' && this.metaOptionId) this.getInfo();
    },
    /**
     * 상세 조회 Process
     */
    async getInfo() {
      this.isLoading.info = true;
      // 상세정보 조회
      const res = await this.$api.getJson(`/meta/option/${this.metaOptionId}`);

      // 성공일 경우
      if (res.ok === 1) {
        this.data = {
          code: res.data.code, // 표준옵션코드
          privateUnitName: res.data['private_unit_name'], // 표준옵션단위(내부용/파트너노출용)
          publicUnitName: res.data['public_unit_name'], // 표준옵션단위(고객노출용)
          optionSizeList: [] // 표준옵션상세정보 리스트
        };

        // 표준옵션상세정보 리스트 설정
        res.data.optionSizeList.forEach(item => {
          const optionData = {
            id: item.id,
            name: item.name,
            orderSeq: item['order_seq']
          };
          this.data.optionSizeList.push(optionData);
        });
      }
      else {
        this.isOpen = false;
      }
      this.isLoading.info = false;
    },
    /**
     * 모달 닫기 (초기화)
     */
    doClose() {
      const optionSizeList = [];
      // 표준옵션상세정보 기본 5개
      for (let i = 0; i < 5; i++) {
        optionSizeList.push({
          id: '',
          name: '',
          orderSeq: i+1
        });
      }

      // 데이터 초기화
      this.type = 'VIEW';
      this.metaOptionId = null;
      this.data = {
        code: '',
        privateUnitName: '',
        publicUnitName: '',
        optionSizeList: optionSizeList
      };
    },
    /**
     * 옵션사이즈 추가
     */
    addOption() {
      this.data.optionSizeList.push({
        id: '',
        name: '',
        orderSeq: this.data.optionSizeList.length + 1
      });
    },
    /**
     * 옵션사이즈 이동(위로/아래로)
     * @param index
     * @param delta
     */
    moveOption(index, delta) {
      let opt = this.data.optionSizeList.splice(index, 1);
      this.data.optionSizeList.splice(index - delta, 0, opt[0]);
      this.setOrderSeq();
    },
    /**
     * 옵션사이즈 삭제
     * @param index
     */
    removeOption(index) {
      this.data.optionSizeList.splice(index, 1);
      this.setOrderSeq();
    },
    /**
     * 정렬순서 설정
     */
    setOrderSeq() {
      this.data.optionSizeList.forEach((item, index) => {
        item.orderSeq = index + 1;
      });
    },
    /**
     * Input 입력가능문자열 체크 및 제거
     * @param e
     * @param type
     */
    doRepInput(e, type) {
      const val = e.target.value;
      let regex = /[^a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣_]/gi;
      if (type === 'size') regex = /[^a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9 _./]/gi;
      if (val) e.target.value = val.toString().replace(regex, '').toUpperCase();
    },
    /**
     * 표준옵션관리 등록 Process
     */
    async doSave() {
      const isValid = await this.doValid(); // 유효성 체크
      if (!isValid) return false;

      const res = await this.$api.postJson(`/meta/option/save`, this.data);
      // 성공일 경우
      if (res.ok === 1) {
        await utils.alert(`표준 옵션이 등록되었습니다.`);
        this.$emit('save');
        this.isOpen = false;
      }
    },
    /**
     * 유효성 체크
     */
    async doValid() {
      if (!this.data.privateUnitName) {
        alert('표준 옵션 단위(내부용/파트너노출용)를 입력해주세요.');
        return false;
      }

      if (!this.data.publicUnitName) {
        alert('표준 옵션 단위(고객노출용)를 입력해주세요.');
        return false;
      }

      if (this.data.optionSizeList.length === 0) {
        alert('표준 옵션 상세 정보를 입력해주세요.');
        return false;
      }

      const optionSizeNameList = [];
      for (const item of this.data.optionSizeList) {
        if (!item.name) {
          alert('표준 옵션 상세 정보를 입력해주세요.');
          return false;
        }

        if (optionSizeNameList.includes(item.name)) {
          alert('동일한 표준 옵션 상세명이 존재합니다.');
          return false;
        }
        optionSizeNameList.push(item.name);
      }
      return true;
    }
  }
}
</script>
<style scoped>
  .item-box:not(:last-child) {
    margin-bottom: 20px;
  }

  .option-size-area {
    padding: 15px 0;
    max-height: calc(100vh - 500px);
    overflow: auto;
  }

  .size-item-box {
    display: flex;
    align-items: center;
  }

  .size-item-box {
    height: 35px;
  }
  .size-index {
    width: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .size-item-box:not(:last-child) {
    margin-bottom: 10px;
  }

  .size-item-box input {
    min-width: 150px;
  }

  .size-item-box button {
    width: 35px;
    height: 35px;
  }

  .btn-size-area {
    margin-left: 10px;
    width: 100%;
    overflow-x: auto;
    text-wrap: nowrap;
  }
</style>
